import request from '@/utils/request'

// 查询商家任务列表
export function listTask(query) {
  return request({
    url: '/task/list',
    method: 'get',
    params: query
  })
}

// 查询商家任务详细
export function getTask(id) {
  return request({
    url: '/task/' + id,
    method: 'get'
  })
}

// 新增商家任务
export function addTask(data) {
  return request({
    url: '/task',
    method: 'post',
    data: data
  })
}

// 修改商家任务
export function updateTask(data) {
  return request({
    url: '/task',
    method: 'put',
    data: data
  })
}

// 删除商家任务
export function delTask(id) {
  return request({
    url: '/task/' + id,
    method: 'delete'
  })
}

// 查询所有人气类型枚举
export function taskTypeMap() {
  return request({
    url: '/task/type/map',
    method: 'get'
  })
}
