<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="任务类型 具体参考TaskTypeEnum" prop="taskType" >
        <a-select v-model="form.taskType" placeholder="请选择任务类型" style="width: 100%" allowClear>
          <a-select-option v-for="(value, key) in taskTypeMap" :key="key" :value="parseInt(key)">
            {{ value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否循环" prop="isLoop" >
        <a-switch v-model="form.isLoop" />
      </a-form-model-item>
      <a-form-model-item label="人气值" prop="popularValue" >
        <a-input v-model="form.popularValue" placeholder="请输入人气值" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="任务标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入任务标题" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="标题单位" prop="titleContent" >
        <a-input v-model="form.titleContent" placeholder="请输入标题单位" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="任务内容" prop="content" >
        <a-input v-model="form.content" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="右下角内容" prop="rightDownContent" >
        <a-input v-model="form.rightDownContent" placeholder="请输入右下角内容" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="右下角单位" prop="rightDownUnit" >
        <a-input v-model="form.rightDownUnit" placeholder="请输入右下角单位" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-select placeholder="请选择状态" style="width: 100%" v-model="form.status" allowClear>
          <a-select-option :value=1>开启</a-select-option>
          <a-select-option :value=2>停用</a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">-->
<!--        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">-->
<!--        <a-input v-model="form.version" placeholder="请输入乐观锁" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTask, addTask, updateTask, taskTypeMap } from '@/api/task/task'
import Editor from '@/components/Editor'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        taskType: null,
        isLoop: false,
        popularValue: null,
        title: null,
        content: null,
        titleContent: null,
        rightDownContent: null,
        rightDownUnit: null,
        status: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        taskType: [
          { required: true, message: '任务类型 具体参考TaskTypeEnum不能为空', trigger: 'change' }
        ],
        isLoop: [
          { required: true, message: '是否循环不能为空', trigger: 'change' }
        ],
        popularValue: [
          { required: true, message: '人气值不能为空', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '任务标题不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      },
      taskTypeMap: []
    }
  },
  filters: {
  },
  created () {
    taskTypeMap().then(res => {
      this.taskTypeMap = res.data
    })
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        taskType: null,
        isLoop: false,
        popularValue: null,
        title: null,
        content: null,
        titleContent: null,
        rightDownContent: null,
        rightDownUnit: null,
        status: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTask(id).then(response => {
        this.form = response.data
        this.$forceUpdate()
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTask(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTask(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
